import { Turbo } from "@hotwired/turbo-rails";
Turbo.setFormMode("optin");

import TurboPower from "turbo_power";
TurboPower.initialize(Turbo.StreamActions);

Turbo.StreamActions.scroll_reset = function () {
  window.scrollTo(0, 0);
};

import "../src/plugins/plugin-jquery";
import "../src/plugins/plugin-jquery-ui";
import "../src/plugins/plugin-bootstrap";
import "../src/plugins/plugin-bootstrap-slider";
import "../src/plugins/plugin-bootstrap-select";
import "../src/plugins/plugin-slick";
import "../src/plugins/plugin-magnific-popup";
import "../src/plugins/plugin-ahoy";
import "../src/plugins/plugin-moment-with-locales";

import "../src/app.js";

import "../controllers/index_public.js";

import "../src/shared/add_html_data_turbo_loaded.js"; // always at the end of this file
