import { application } from "./application";

// import all public controllers

import controllers from "./public/**/*_controller.js";
controllers.forEach((controller) => {
  var controllerName = controller.name.replaceAll(".--", "");
  var controllerImpl = controller.module.default;
  application.register(controllerName, controllerImpl);
});

// cherry pick other controllers

// ---components---
import AddressAutocompleteController from "./components/address_autocomplete_controller";
application.register("components--address-autocomplete", AddressAutocompleteController);

import ObfuscatedLinkController from "./components/obfuscated_link_controller";
application.register("components--obfuscated-link", ObfuscatedLinkController);

import ClickOnLoadController from "./widgets/click_on_load_controller";
application.register("widgets--click-on-load", ClickOnLoadController);

import EventDispatcher from "./widgets/event_dispatcher_controller";
application.register("widgets--event-dispatcher", EventDispatcher);

import FormElementsReadonlyController from "./widgets/form_elements_readonly_controller";
application.register("widgets--form-elements-readonly", FormElementsReadonlyController);

import ProCheckboxTogglerController from "./widgets/pro_checkbox_toggler_controller";
application.register("widgets--pro-checkbox-toggler", ProCheckboxTogglerController);

import PopupMobileStoresController from "./widgets/popup_mobile_stores_controller";
application.register("widgets--popup-mobile-stores", PopupMobileStoresController);

import HideAfterClickController from "./widgets/hide_after_click_controller";
application.register("widgets--hide-after-click", HideAfterClickController);

import ImageLazyLoadingController from "./widgets/image_lazy_loading_controller";
application.register("widgets--image-lazy-loading", ImageLazyLoadingController);

import MagnificPopupController from "./widgets/magnific_popup_controller";
application.register("widgets--magnific-popup", MagnificPopupController);

import PopoverController from "./widgets/popover_controller";
application.register("widgets--popover", PopoverController);

import ScrollToController from "./widgets/scroll_to_controller";
application.register("widgets--scroll-to", ScrollToController);

import ScrollResetController from "./widgets/scroll_reset_controller";
application.register("widgets--scroll-reset", ScrollResetController);

import YoutubePopupController from "./widgets/youtube_popup_controller";
application.register("widgets--youtube-popup", YoutubePopupController);

import InputSliderController from "./widgets/input_slider_controller";
application.register("widgets--input-slider", InputSliderController);

import ToggleRadioController from "./widgets/toggle_radio_controller";
application.register("widgets--toggle-radio", ToggleRadioController);

// ---root---
import ElementLinkController from "./element_link_controller";
application.register("element-link", ElementLinkController);

import HorizontalScrollController from "./horizontal_scroll_controller";
application.register("horizontal-scroll", HorizontalScrollController);

import ModalController from "./modal_controller";
application.register("modal", ModalController);

import SelectpickerController from "./selectpicker_controller";
application.register("selectpicker", SelectpickerController);

import PagyInitializerController from "./pagy_initializer_controller";
application.register("pagy-initializer", PagyInitializerController);

import ToastController from "./toast_controller";
application.register("toast", ToastController);

import FlatpickrController from "./flatpickr_controller";
application.register("flatpickr", FlatpickrController);

import CheckboxButtonController from "./checkbox_button_controller";
application.register("checkbox-button", CheckboxButtonController);

import TurboFrameHistoryController from "./turbo_frame_history_controller";
application.register("turbo-frame-history", TurboFrameHistoryController);

import SessionsPasswordVisibilityController from "./sessions/password_visibility_controller";
application.register("sessions--password-visibility", SessionsPasswordVisibilityController);

import SessionsPasswordLengthController from "./sessions/password_length_controller";
application.register("sessions--password-length", SessionsPasswordLengthController);

import AcceptTurboStreamController from "./accept_turbo_stream_controller";
application.register("accept-turbo-stream", AcceptTurboStreamController);

import BootstrapSliderController from "./bootstrap_slider_controller";
application.register("bootstrap-slider", BootstrapSliderController);
